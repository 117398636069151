.Error {
  &__logo {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
  }

  &__title,
  &__description {
    text-align: center;
    font-family: 'Sora';
  }

  &__title {
    font-weight: 600;
    line-height: 20px;
    color: #000;
    margin-bottom: 5px;
  }

  &__description {
    width: 281px;
    margin: 0 auto;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    text-align: center;
    font-size: 0.875rem;
    margin-bottom: 2.125rem;
  }

  &__boundaryContainer {
    max-width: 360px;
    margin: 0 auto;
  }

  &__boundary {
    max-width: 321px;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 2.5px 0px;
    background-color: #fff
  }
}