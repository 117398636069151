.expired-payment-header {
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
  }

  &-content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 45px;
  }
}

.payment-header {
  &__warning {
    height: 64px;
    width: 'auto';
    display: 'block';
    margin: auto;
    margin-bottom: 20px;
    margin-top: 37px;
  }
}

.btn {
  &__grey {
    width: 100%;
    padding: 14px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #000000;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &__transparent {
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding: 14px;
    width: 100%;
    border-radius: 10px;
    border-color: transparent;
    cursor: pointer;
    margin-top: 20px;
    transition: all 300ms ease-in-out;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &__red {
    width: 100%;
    padding: 14px;
    background: #d24339;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &:hover {
      background: rgb(210, 67, 57, 0.8);
    }
  }

  &__transparent__red {
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #d24339;
    padding: 14px;
    width: 100%;
    border-radius: 10px;
    border-color: transparent;
    cursor: pointer;
    margin-top: 20px;
    transition: all 300ms ease-in-out;

    &:hover {
      transform: translateY(-2px);
    }
  }
}

.payment-header {
  border: none;
  margin-bottom: 45px;
  line-height: 20px;
  padding-bottom: 0;
}

.payment-header__warning {
  margin-top: 35px !important;
  margin-bottom: 25px !important;
}
